import { CountryInterface } from "country-codes-flags-phone-codes";
import { useEffect, useRef, useState } from "react";

export const CountrySearch = ({ onSearch, countries }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    const results = countries.filter(
      (country: CountryInterface) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        country.code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    onSearch(results);
  }, [searchTerm, countries, onSearch]);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search country"
        value={searchTerm}
        onChange={handleSearchChange}
        ref={inputRef}
        className="bg-secondaryLight text-white p-2 pl-10 rounded-md w-full outline-none h-12"
      />
    </div>
  );
};
