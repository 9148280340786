import React, { useEffect, useState } from "react";

interface TimeLabelProps {
  hours: number;
  minutes: number;
  seconds: number;
}

const TimeLabel: React.FC<TimeLabelProps> = ({ seconds }) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  const numberStyle = { color: "white" };
  const letterStyle = { color: "grey" };

  // small lines between each number and letter group
  return (
    <div className="flex items-center">
      <img src="/hour-glass.svg" alt="hourglass" className="w-4" />
      <span style={numberStyle}>{h}</span>
      <span style={letterStyle}>h </span>
      <div className="w-px h-2.5 bg-primary mx-1" />
      <span style={numberStyle}>{m}</span>
      <span style={letterStyle}>m </span>
      <div className="w-px h-2.5 bg-primary mx-1" />
      <span style={numberStyle}>{s}</span>
      <span style={letterStyle}>s</span>
    </div>
  );
};

function secondsUntilDate(targetDate: Date): number {
  const now = new Date();
  const diffInSeconds = Math.floor(
    (targetDate.getTime() - now.getTime()) / 1000
  );
  return diffInSeconds;
}

function Group({
  buttonPress,
  buttonText,
  title,
  subtitle,
  images,
  expiresAt,
}: any) {
  const [timeLeft, setTimeLeft] = useState(secondsUntilDate(expiresAt));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(secondsUntilDate(expiresAt));
    }, 1000);

    return () => clearInterval(interval);
  }, [expiresAt]);

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex items-center p-4 h-80 w-80 mb-6">
          <ImagesGrid images={images} />
        </div>
        <h1 className="text-white mb-2 text-xl font-semibold text-center px-3">
          {title}
        </h1>
        <label className="text-white mb-2 text-sm">{subtitle}</label>
        <div className="border-secondaryLighter border rounded-xl w-32 h-8 mb-8 flex items-center justify-center bg-transparent">
          <TimeLabel hours={timeLeft} minutes={timeLeft} seconds={timeLeft} />
        </div>
        <button
          className={`bg-white text-secondaryLighter p-2 rounded-full h-12 flex items-center justify-center px-10`}
          onClick={buttonPress}
        >
          {buttonText}
          <img src="/>.svg" alt="group" className="h-3 pl-3" />
        </button>
      </div>
    </>
  );
}

export default Group;

interface ImageItemProps {
  src: string;
  alt: string;
  className?: string;
  overlayText?: string;
}

const ImageItem: React.FC<ImageItemProps> = ({
  src,
  alt,
  className,
  overlayText,
}) => {
  return (
    <div className={`relative w-full h-full overflow-hidden ${className}`}>
      <img src={src} alt={alt} className="object-cover w-full h-full" />
      {overlayText && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <span className="text-white text-2xl font-bold">{overlayText}</span>
        </div>
      )}
    </div>
  );
};

interface ImagesGridProps {
  images: string[];
}

const ImagesGrid: React.FC<ImagesGridProps> = ({ images }) => {
  const imageCount = images.length;

  const renderImages = () => {
    if (imageCount === 1) {
      return (
        <ImageItem
          src={images[0]}
          alt="group-0"
          className="rounded-lg w-80 h-80"
        />
      );
    }
    if (imageCount === 2) {
      return (
        <div className="grid grid-rows-2 gap-1 h-80 w-80">
          {images.map((src, index) => (
            <ImageItem
              key={index}
              src={src}
              alt={`group-${index}`}
              className={index === 0 ? "rounded-t-lg" : "rounded-b-lg"}
            />
          ))}
        </div>
      );
    } else if (imageCount === 3) {
      return (
        <div className="grid grid-rows-2 gap-1 h-80 w-80">
          <div className="grid grid-cols-2 gap-1">
            {images.slice(0, 2).map((src, index) => (
              <ImageItem
                key={index}
                src={src}
                alt={`group-${index}`}
                className={index === 0 ? "rounded-tl-lg" : "rounded-tr-lg"}
              />
            ))}
          </div>
          <ImageItem src={images[2]} alt="group-2" className="rounded-b-lg" />
        </div>
      );
    } else if (imageCount >= 4) {
      return (
        <div className="grid grid-cols-2 gap-1 h-80 w-80">
          {images.slice(0, 3).map((src, index) => (
            <ImageItem
              key={index}
              src={src}
              alt={`group-${index}`}
              className={
                index === 0
                  ? "rounded-tl-lg"
                  : index === 1
                  ? "rounded-tr-lg"
                  : ""
              }
            />
          ))}
          <ImageItem
            src={images[3]}
            alt="group-3"
            className="rounded-bl-lg rounded-br-lg"
            overlayText={imageCount > 4 ? `+${imageCount - 4}` : undefined}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return <>{renderImages()}</>;
};
