export const openMobileApp = () => {
  window.open(getAppStoreLink(), "_blank");
};

const getAppStoreLink = () => {
  const platform = getPlatform();
  if (platform === "Android") {
    return "market://details?id=dk.doubble.dating";
  } else if (platform === "iOS") {
    return "https://apps.apple.com/us/app/doubble/id1572234875";
  } else {
    return "https://doubble.app";
  }
};

const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "Other";
};
