import { AnimatePresence, motion } from "framer-motion";

export function AnimatedPages({ pages, currentPage, prevPage }: any) {
  return (
    <AnimatePresence
      mode="popLayout"
      initial={false}
      custom={prevPage < currentPage ? 1 : -1}
    >
      <motion.div
        className="step-content max-w-md h-5/6 bg-secondaryLight p-3 rounded-lg mx-auto absolute left-0 right-0 top-20 z-20"
        key={currentPage}
        custom={prevPage < currentPage ? 1 : -1}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={{
          hidden: (direction) => ({
            x: direction === 1 ? "100%" : "-100%",
          }),
          visible: { x: 0 },
          exit: (direction) => ({ x: direction === 1 ? "-100%" : "100%" }),
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        {pages[currentPage]}
      </motion.div>
    </AnimatePresence>
  );
}
