// Spinner.tsx
import React from "react";

type SpinnerProps = {
  size?: number;
};

const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  return (
    <div
      className={`spinner-border animate-spin inline-block w-${size ?? 4} h-${
        size ?? 4
      } border-2 rounded-full border-t-transparent border-primary`}
    ></div>
  );
};

export default Spinner;
