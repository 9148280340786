import { useRef, useState } from "react";
import Spinner from "../spinner";

import { LandCodeSelector } from "../land-code-selector";
import {
  CountryInterface,
  getCountryFromCountryCode,
} from "country-codes-flags-phone-codes";
import phone from "phone";

type TonightPhoneInputProps = {
  submitPhoneNumber: (name: string) => Promise<boolean>;
  initialNumber: string;
  inviterName: string;
};

function TonightPhoneInput({
  submitPhoneNumber,
  initialNumber,
  inviterName,
}: TonightPhoneInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [phoneNumber, setPhoneNumber] = useState(initialNumber.split(" ")[1]);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState<CountryInterface>(
    getCountryFromCountryCode("DK")!
  );

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(e.target.value.replace(/\s/g, ""));
    setIsValid(true);
  };

  const validateInput = async (phoneNumber: string) => {
    const valid = phone(phoneNumber, {
      country: selectedCountry.code,
      strictDetection: false,
      validateMobilePrefix: false,
    });

    setIsValid(valid.isValid);
    return valid.isValid;
  };

  const submit = () => {
    setLoading(true);
    const numberWithDialCode = selectedCountry.dialCode + " " + phoneNumber;
    validateInput(numberWithDialCode).then((valid) => {
      if (valid) {
        submitPhoneNumber(numberWithDialCode);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <h2 className="text-white mb-4 text-l font-semibold text-center px-3">
          create quick profile to save your invitation from {inviterName}
        </h2>
        <label className="text-white mb-4 text-sm">
          what is your phone number?
        </label>
        <div className="flex items-center mb-3 w-full">
          <LandCodeSelector
            selectedCountry={selectedCountry}
            setSelectedCountry={(c) => {
              setSelectedCountry(c);
              setIsValid(true);
            }}
          />
          <div className="w-3"></div>
          <input
            type="tel"
            placeholder="phone number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            ref={inputRef}
            className="bg-secondaryLighter text-white p-2 rounded-md w-full outline-none h-12"
          />
        </div>
        {!isValid && (
          <div className="text-red-500 mb-4">not a valid phone number</div>
        )}
        <button
          className={`p-2 rounded-md w-full flex flex-row justify-center items-center h-12 ${
            phoneNumber && !loading && isValid
              ? "bg-white text-black"
              : "bg-disabled text-onDisabled"
          } `}
          disabled={!phoneNumber || loading}
          onClick={submit}
        >
          {loading && (
            <>
              <Spinner />
              <div className="w-4" />
            </>
          )}
          continue
        </button>
      </div>
    </>
  );
}

export default TonightPhoneInput;
