import { CloseButton } from "@headlessui/react";
import { useEffect, useState } from "react";
import { AnimatedPages } from "../components/animated-pages";
import { BackButton } from "../components/back-button";
import { BottomActionSheet } from "../components/bottom-action-sheet";
import Confetti from "../components/confetti";
import Spinner from "../components/spinner";
import Stepper from "../components/stepper-line";
import Group from "../components/tonight/group";
import TonightNameInput from "../components/tonight/tonight-name-input";
import TonightPhoneInput from "../components/tonight/tonight-phone-input";
import { API_URL } from "../consts";
import { useGetInvitationDetailsQuery } from "../shared/utilities/__generated__/graphql";
import { openMobileApp as redirectToAppOrWebsite } from "../utils";

async function acceptInvite(
  phoneNumber: string,
  name: string,
  contextData: { inviterId: string; groupId: string }
) {
  const response = await fetch(API_URL + "/tonight/accept-invite", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phoneNumber,
      name,
      contextData,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to accept invite");
  }

  const data = await response.json();
  return data;
}

function nextNoon(): Date {
  const now = new Date();
  const nextNoon = new Date();
  nextNoon.setHours(12, 0, 0, 0);
  if (now.getHours() >= 12) {
    nextNoon.setDate(nextNoon.getDate() + 1);
  }
  return nextNoon;
}

function TonightPage() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [prevStep, setPrevStep] = useState<number>(0);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [completed, setCompleted] = useState(false);

  // URL Params
  const [groupId, setGroupId] = useState<string | null>(null);
  const [inviterId, setInviterId] = useState<string | null>(null);
  const [sessionsEndsAt, setSessionsEndsAt] = useState<Date | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setGroupId(params.get("groupId"));
    setInviterId(params.get("inviterId"));
    const endsAt = params.get("sessionsEndsAt");

    if (endsAt) {
      const date = new Date(endsAt);
      if (!isNaN(date.getTime())) {
        setSessionsEndsAt(date);
      } else {
        console.error("Invalid date format for sessionsEndsAt");
      }
    }
  }, []);

  const finalSubmit = async (
    phoneNumber: string,
    name: string
  ): Promise<boolean> => {
    return acceptInvite(phoneNumber, name, {
      inviterId: inviterId!,
      groupId: groupId!,
    })
      .then(() => {
        increaseStep(1);
        setCompleted(true);
        return true;
      })
      .catch((error) => {
        console.error(error);
        redirectToAppOrWebsite();
        return false;
      });
  };

  const maxSteps = 3;
  const increaseStep = (increment: number) => {
    const nextStep = (currentStep + increment) % maxSteps;
    setPrevStep(currentStep);
    setCurrentStep(nextStep);
  };

  const closeBAS = () => {
    setPhoneNumber("");
    setName("");
    setPrevStep(0);
    setCurrentStep(0);
  };

  const { data, loading, error } = useGetInvitationDetailsQuery({
    variables: { groupId: groupId! },

    skip: !groupId || !inviterId, // Skip the query if groupId or inviterId is not set
    onError: async (error) => {
      await new Promise((resolve) => setTimeout(resolve, 2500));
      redirectToAppOrWebsite();
    },
  });

  const inviterIndex =
    data?.getInvitationDetails.findIndex((m) => m.userId === inviterId) ?? -1;

  const inviter = data?.getInvitationDetails[inviterIndex];

  const inviterFirstName = inviter?.name.split(" ")[0];

  let images = data?.getInvitationDetails.map((m) => m.imagePath) ?? [];

  // Move the inviter's image to the beginning of the array
  if (inviterIndex !== -1 && images.length > 0) {
    const inviterImage = images.splice(inviterIndex, 1)[0];
    images = [inviterImage, ...images];
  }

  const orderedSteps = [
    null,
    <TonightNameInput
      key="name"
      inviterName={inviterFirstName ?? "your friend"}
      submitName={async (name: string) => {
        increaseStep(1);
        setName(name);
        return true;
      }}
    />,
    <TonightPhoneInput
      key="number"
      inviterName={inviterFirstName ?? "your friend"}
      initialNumber={phoneNumber}
      submitPhoneNumber={(pn) => {
        return finalSubmit(pn, name);
      }}
    />,
  ];

  const currentStepComponent = orderedSteps[currentStep];

  return (
    <div className="bg-background h-screen relative">
      {completed && <Confetti />}

      <header className="h-28 z-10">
        <div className="flex justify-between w-full">
          <button className="absolute top-0 left-0 m-2">
            <img src="/logo.svg" alt="logo" className="w-32" />
          </button>
          <button
            className="absolute font-bold top-0 right-0 bg-primary text-onPrimary hover:text-purple-500 text-sm p-2 rounded-lg m-2"
            onClick={redirectToAppOrWebsite}
          >
            open app
          </button>
        </div>
      </header>

      {loading && (
        <div className="flex flex-col items-center">
          <Spinner size={8} />
        </div>
      )}

      {error && (
        <div className="flex flex-col items-center">
          <h1 className="text-white mb-2 text-xl font-semibold text-center px-3">
            {
              "Tonight Group invitation could not be loaded... Redirecting to the app!"
            }
          </h1>
          <div className="flex flex-col items-center mt-8">
            <Spinner size={8} />
          </div>
          <button
            onClick={redirectToAppOrWebsite}
            className={`bg-white text-secondaryLighter p-2 rounded-full h-12 flex items-center justify-center px-10 mt-24`}
          >
            take me to the app!
          </button>
        </div>
      )}

      {!loading && data && !error && (
        <Group
          title={
            completed
              ? `Invitation from ${inviterFirstName} saved`
              : `${inviterFirstName} invited you to join a group`
          }
          subtitle={
            completed
              ? "get Doubble to join the group"
              : "group expires tonight"
          }
          buttonText={completed ? "get Doubble" : "join group"}
          buttonPress={
            completed ? redirectToAppOrWebsite : () => increaseStep(1)
          }
          images={images ?? []}
          expiresAt={sessionsEndsAt ?? nextNoon()}
          className="z-0"
          completedSignup={completed}
        />
      )}

      <BottomActionSheet show={currentStepComponent !== null}>
        <div className="flex flex-row w-full justify-between">
          {currentStep > 1 ? (
            <BackButton onClick={() => increaseStep(-1)} />
          ) : (
            <div />
          )}
          <CloseButton onClick={closeBAS} />
        </div>
        <Stepper current={currentStep} max={maxSteps} />
        <AnimatedPages
          currentPage={currentStep}
          prevPage={prevStep}
          pages={orderedSteps}
        />
      </BottomActionSheet>
    </div>
  );
}

export default TonightPage;
