import {
  CountryInterface,
  getCountryFlagEmojiFromCountryCode,
} from "country-codes-flags-phone-codes";

interface LandCodeSelectorItemProps {
  country: CountryInterface;
  onSelect: (country: CountryInterface) => void;
}

const LandCodeSelectorItem: React.FC<LandCodeSelectorItemProps> = ({
  country,
  onSelect,
}) => {
  return (
    <div
      className="flex justify-between p-4 border-b border-secondaryLight last:border-b-0 cursor-pointer w-full"
      onClick={() => onSelect(country)}
    >
      <div className="flex items-center space-x-2">
        <div>{getCountryFlagEmojiFromCountryCode(country.code)}</div>
        <div>{country.name}</div>
      </div>
      <div className=""></div>
      <div>{country.dialCode}</div>
    </div>
  );
};

export { LandCodeSelectorItem };
