import { useEffect } from "react";
// import Code from './pages/code';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { API_URL } from "./consts";
import Redirect from "./pages/redirect";
import TonightPage from "./pages/tonight-page";

const client = new ApolloClient({
  uri: API_URL + "/graphql",
  cache: new InMemoryCache(),
});

function App() {
  useEffect(() => {
    // Apply styles to body
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.height = "100%";

    // Prevent default touch move behavior
    const preventDefault = (e: any) => e.preventDefault();
    document.body.addEventListener("touchmove", preventDefault, {
      passive: false,
    });

    // Cleanup function
    return () => {
      // Remove styles from body
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.height = "";

      // Remove event listener
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="/invite/tonight" element={<TonightPage />} />
          {/* Fallback route will just redirect to the app store */}
          <Route path="/*" element={<Redirect />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;
