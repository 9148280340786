
const Stepper = ({current, max}: any) => {

    const getProgressWidth = () => {
        return `${(current / max) * 100}%`;
    };

    return (
        <div className="w-full p-2 flex justify-between">
            <div className="relative w-full h-1 bg-secondaryLighter rounded">
                <div
                    className="absolute h-full bg-white transition-all duration-300 ease-in-out rounded"
                    style={{ width: getProgressWidth() }}
                ></div>
            </div>
        </div>
    );
};

export default Stepper;