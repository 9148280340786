import { AnimatePresence, motion } from "framer-motion";

export function BottomActionSheet({ children, show }: any) {
  return (
    <>
      {show && (
        <div
          className={
            "backdrop-blur-sm absolute inset-0 z-10 transition-all duration-500"
          }
        ></div>
      )}

      <AnimatePresence mode="wait">
        {show && (
          <motion.div
            className="step-content max-w-md h-full bg-secondaryLight p-3 rounded-lg mx-auto absolute left-0 right-0 top-20 z-20"
            key={show}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "spring", stiffness: 120, damping: 20 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
