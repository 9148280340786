import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { countries, CountryInterface } from "country-codes-flags-phone-codes";
import { AnimatePresence, motion } from "framer-motion";
import { ScrollableContainer } from "./scrollable-container";

import { useState } from "react";
import { CountrySearch } from "./country-search";
import { LandCodeSelectorItem } from "./land-code-selector-item";

interface LandCodeSelectorProps {
  selectedCountry: CountryInterface;
  setSelectedCountry: (country: CountryInterface) => void;
}

const LandCodeSelector: React.FC<LandCodeSelectorProps> = ({
  selectedCountry,
  setSelectedCountry,
}) => {
  const [filteredCountries, setCountries] = useState<CountryInterface[]>([]);

  const handleSelectCountry = (country: CountryInterface) => {
    setSelectedCountry(country);
  };
  const countriesWithPriority = ["DK", "SE", "NO", "GB", "NL"];
  const [prioritizedCountries] = useState<CountryInterface[]>(
    countries.sort(
      (a, b) =>
        countriesWithPriority.reverse().indexOf(b.code) -
        countriesWithPriority.reverse().indexOf(a.code)
    )
  );

  return (
    <div className="z-30 ">
      <Menu>
        {({ open }) => (
          <div className="outline-none">
            <div className="bg-secondaryLighter rounded-md text-white">
              <MenuButton>
                <div className="bg-secondaryLighter px-4  rounded-md h-12 w-20 text-white flex flex-row items-center cursor-pointer">
                  <span>{selectedCountry.dialCode}</span>
                  <div className="w-3" />
                  <svg
                    className={`w-4 h-4 transform ${
                      open ? "rotate-180" : "rotate-0"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </MenuButton>
            </div>

            <AnimatePresence>
              {open && (
                <ScrollableContainer>
                  <MenuItems
                    static
                    as={motion.div}
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    anchor={{ to: "bottom start", gap: "8px" }}
                    className="origin-top z-40 box-content w-full bg-background rounded-md border-secondaryLight text-white"
                  >
                    <div className="p-2">
                      <CountrySearch
                        countries={prioritizedCountries}
                        onSearch={(searchResult: CountryInterface[]) => {
                          setCountries(searchResult);
                        }}
                      />
                    </div>
                    {filteredCountries.map((country) => (
                      <MenuItem key={country.code}>
                        <div className="w-full">
                          <LandCodeSelectorItem
                            country={country}
                            onSelect={handleSelectCountry}
                          />
                        </div>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </ScrollableContainer>
              )}
            </AnimatePresence>
          </div>
        )}
      </Menu>
    </div>
  );
};

export { LandCodeSelector };
