import { useEffect } from "react";
import { openMobileApp } from "../utils";

const Redirect: React.FC = () => {
  useEffect(() => {
    openMobileApp();
  }, []);

  return <div>Redirecting to the app store...</div>;
};

export default Redirect;
