import React, { useState } from "react";
import Spinner from "../spinner";
type TonightNameInputProps = {
  submitName: (name: string) => Promise<boolean>;
  inviterName: string;
};

const TonightNameInput: React.FC<TonightNameInputProps> = ({
  submitName,
  inviterName,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (name.length > 0) {
      await submitName(name);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center w-full">
        <h2 className="text-white mb-4 text-l font-semibold text-center px-3">
          Create quick profile to save your invitation from {inviterName}
        </h2>
        <label className="text-white mb-4 text-sm">what is your name?</label>
        <div className="flex items-center mb-3 w-full">
          <input
            type="text"
            placeholder="Name"
            className="bg-secondaryLighter text-white p-2 rounded-md w-full outline-none h-12"
            onChange={(e) => setName(e.target.value)}
          />
          <div className="w-3"></div>
          <button
            className={`p-2 rounded-md w-24 h-12  ${
              name && !loading
                ? "bg-white text-black"
                : "bg-disabled text-onDisabled"
            } `}
            onClick={() => {
              setLoading(true);
              submit();
            }}
            disabled={!name || loading}
          >
            {loading ? (
              <>
                <Spinner />
                <div className="w-4" />
              </>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default TonightNameInput;
