import { useEffect } from "react";

export const ScrollableContainer = ({ children, className = "" }: any) => {
  useEffect(() => {
    // Apply styles to body
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.height = "100%";

    // Prevent default touch move behavior
    const preventDefault = (e: any) => e.preventDefault();
    document.body.addEventListener("touchmove", preventDefault, {
      passive: false,
    });

    // Cleanup function
    return () => {
      // Remove styles from body
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.height = "";

      // Remove event listener
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  return (
    <div
      className={`overflow-y-auto h-full -webkit-overflow-scrolling-touch ${className}`}
      onTouchMove={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
};
